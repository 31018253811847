<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
      style="background: #fff"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = ''; showSearchBar = !showSearchBar"
                  />
                </b-input-group-append>
              </b-input-group>

              <b-dropdown
                id="dropdown-form-purchase-request"
                ref="filter_dropdown_all_delivery_receipt"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="dropdown-modal"
                right
                variant="link"
              >
                <template #button-content>
                  <feather-icon
                    icon="FilterIcon"
                    size="21"
                    class="align-middle mr-50"
                  />
                  <span class="mr-1">Filters</span>
                </template>
                <ncf-filters
                  :status-filter.sync="statusFilter"
                  :solution-filter.sync="solutionFilter"
                  :created-at.sync="createdAtFilter"
                  :type-filter.sync="typeFilter"
                  :status-options="statusOptions"
                  @emitHideFilter="hideFilter"
                  @emitShowFilter="showFilter"
                />
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
        <div v-if="createdAtFilter || typeFilter || statusFilter.length || solutionFilter.length">
          <hr class="dividerHR filter-divider">
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <ul
                class="list-inline d-inline-block mb-1"
              >
                <li
                  v-for="statusVal in statusFilter"
                  :key="statusVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromStatusFilter(statusVal)"
                  >
                    <span class="text-capitalize">{{ statusVal == 'pending' ? 'Pending Signatories' : statusVal }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-if="createdAtFilter"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="createdAtFilter = ''"
                  >
                    {{ createdAtFilter }}
                  </b-form-tag>
                </li>
                <li
                  v-if="typeFilter"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="typeFilter = ''"
                  >
                    {{ typeFilter }}
                  </b-form-tag>
                </li>
                <li
                  v-for="solutionVal in solutionFilter"
                  :key="solutionVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromSolutionFilter(solutionVal)"
                  >
                    <span>{{ solutionVal }}</span>
                  </b-form-tag>
                </li>
                <li
                  class="list-inline-item"
                >
                  <span
                    class="text-danger clear-filter-text mb-50"
                    @click="clearAllFilters"
                  >
                    Clear Filters
                  </span>
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </div>

      <b-table
        ref="refPendingNCFsTable"
        class="position-relative event_list has_padding delivery_table"
        :items="fetchNCFs"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Role -->
        <template #cell(stringID)="data">
          <div class="text-nowrap">
            <div v-if="canViewThisAction('show', 'NonConformanceForm')">
              <b-button
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="text-bold-black cursor-pointer align-middle detail-view-id"
                :to="{ name: 'purchasing-ncf-show', params: { id: data.item._id }, query: { from: $route.name == 'purchasing-my-ncf-index' ? 'my-ncf' : '' } }"
              >
                {{ data.item.stringID }}
              </b-button>
            </div>
            <div v-else>
              <span>{{ data.item.stringID }}</span>
            </div>
            <div v-if="canViewThisAction('show', 'PurchaseRequest')">
              <b-button
                variant="link"
                class="p-0"
                :to="{ name: 'purchasing-purchase-request-show', params: { id: data.item.purchaseRequest ? data.item.purchaseRequest._id : 0 }, query: { from: $route.name == 'purchasing-my-ncf-index' ? 'my-pr' : '' } }"
              >
                {{ data.item.purchaseRequest ? data.item.purchaseRequest.caseID : '' }}
              </b-button>
            </div>
            <div v-else>
              <span>{{ data.item.purchaseRequest ? data.item.purchaseRequest.caseID : '' }}</span>
            </div>
          </div>
        </template>

        <template #cell(createdAt)="data">
          <div class="text-nowrap">
            <span class="">{{ dateFormatWithTime(data.item.createdAt) }}</span>
          </div>
        </template>

        <template #cell(vendor)="data">
          <div class="text-nowrap">
            <div v-if="canViewThisAction('show', 'Vendor')">
              <b-button
                variant="link"
                class="p-0"
                :to="{ name: 'purchasing-vendors-show', params: { id: data.item.vendor ? data.item.vendor._id : 0 } }"
              >
                {{ data.item.vendor ? data.item.vendor.companyName : '' }}
              </b-button>
            </div>
            <div v-else>
              <span>{{ data.item.vendor ? data.item.vendor.companyName : '' }}</span>
            </div>
          </div>
        </template>

        <template #cell(createdBy)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.createdBy ? data.item.createdBy.name : '' }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveNCFStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status == 'pending' ? 'Pending Signatories' : data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="canViewThisAction('update', 'NonConformanceForm') || canViewThisAction('show', 'NonConformanceForm')"
          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="canViewThisAction('show', 'NonConformanceForm')"
              :to="{ name: 'purchasing-ncf-show', params: { id: data.item._id }, query: { from: $route.name == 'purchasing-my-ncf-index' ? 'my-ncf' : '' } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="(data.item.status != 'cancelled' && data.item.status != 'closed') && (canViewThisAction('update', 'NonConformanceForm') || user._id == (data.item.createdBy ? data.item.createdBy._id : ''))"
              @click="showCancelWarning(data.item._id, data.item.stringID)"
            >
              <feather-icon icon="XCircleIcon" />
              <span class="align-middle ml-50">Cancel NCF</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item
              v-if="canViewThisAction('update', 'NCF')"
              :to="{ name: 'purchasing-ncf-edit', params: { id: data.item._id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </template>

      </b-table>
    </b-card>

    <div
      v-if="totalNCFs > perPage"
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalNCFs"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BInputGroup, BInputGroupAppend,
  BBadge, BDropdown, BDropdownItem, BPagination, BButton, BFormTag,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

import store from '@/store'

import usePendingNCFList from './usePendingNCFList'
import ncfStoreModule from '../ncfStoreModule'
import ncfFilters from '../NCFFilters.vue'

export default {
  components: {
    // UserListAddNew,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BFormTag,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    ncfFilters,
  },
  directives: {
    Ripple,
  },
  // props: {
  //   vendorOptions: {
  //     type: Array,
  //     required: true,
  //   },
  // },
  data() {
    return {
      bulkStatus: '',
      bulkStatusValidation: false,
      bulkStatusError: 'Valid status is required',
      statusDataOptions: [
        { title: 'Draft', code: 'draft' },
        { title: 'Closed', code: 'closed' },
        { title: 'Pending Signatories', code: 'pending' },
      ],
      showFilterDropdown: process.env.VUE_APP_SHOW_FILTER_DROPDOWN,

      required,
    }
  },

  methods: {
    hideFilter() {
      this.$refs.filter_dropdown_all_delivery_receipt.hide(true)
    },
    showFilter() {
      this.$refs.filter_dropdown_all_delivery_receipt.show(true)
    },
    removeFromStatusFilter(item) {
      const index = this.statusFilter.indexOf(item)
      if (index > -1) {
        this.statusFilter.splice(index, 1)
      }
    },
    removeFromSolutionFilter(item) {
      const index = this.solutionFilter.indexOf(item)
      if (index > -1) {
        this.solutionFilter.splice(index, 1)
      }
    },

    clearAllFilters() {
      this.createdAtFilter = ''
      this.typeFilter = ''
      this.statusFilter = []
      this.solutionFilter = []
    },
    deleteEntity(id) {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Selecting Delete will <strong>permanently delete</strong> this item. This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Delete!',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.toDeletedID = id
            this.deleteNCF()
          }
        })
    },
    showCancelWarning(id, caseID) {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Cancelling this NCF will not allow you to edit this anymore. You will have to create a new NCF.<br><br><span class="text-bold-black">This action cannot be undone.</span>',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, go back',
        confirmButtonText: 'Yes, cancel this NCF',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$http.post(`purchase/ncf/${id}/cancel`, {})
              .then(() => {
                this.$emit('emitCountNCF')
                this.refetchData()
                this.$swal({
                  title: 'NCF Cancelled!',
                  html: `NCF <strong>${caseID}</strong> has been cancelled`,
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/success.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const showFilterOptions = ref(false)
    const NCF_APP_STORE_MODULE_NAME = 'app-pending-ncf'

    // Register module
    if (!store.hasModule(NCF_APP_STORE_MODULE_NAME)) store.registerModule(NCF_APP_STORE_MODULE_NAME, ncfStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(NCF_APP_STORE_MODULE_NAME)) store.unregisterModule(NCF_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { label: 'Draft', value: 'draft' },
      { label: 'Pending Signatories', value: 'pending' },
      { label: 'Closed', value: 'closed' },
    ]

    const { canViewThisAction } = useAclUtils()

    const {
      fetchNCFs,
      tableColumns,
      perPage,
      currentPage,
      totalNCFs,
      ncfData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPendingNCFsTable,
      refetchData,

      toDeletedID,
      deleteNCF,

      // UI
      resolveNCFStatusVariant,

      // Extra Filters
      statusFilter,
      solutionFilter,
      createdAtFilter,
      typeFilter,
    } = usePendingNCFList()

    return {

      // Sidebar

      fetchNCFs,
      tableColumns,
      perPage,
      currentPage,
      totalNCFs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPendingNCFsTable,
      refetchData,
      showSearchBar,
      showFilterOptions,
      toDeletedID,
      deleteNCF,
      ncfData,

      // Filter
      avatarText,
      canViewThisAction,

      // UI
      resolveNCFStatusVariant,

      statusOptions,

      // Extra Filters
      statusFilter,
      solutionFilter,
      typeFilter,
      createdAtFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
